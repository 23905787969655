<template>
  <div class="mail-filter">
    <div class="filter-items">
      <DatePicker
        ref="datePickerRef"
        class="date-picker"
        :class="{ active: received_atFilterOpen }"
        :type="datePickerType"
        format="yyyy-MM-dd"
        :open="received_atFilterOpen"
        :options="datePickerOptions"
        :transfer="true"
        :clearable="true"
        placeholder="Select date"
        split-panels
        @on-change="onDateFilterChange"
        @on-clickoutside="
          () => {
            received_atFilterOpen = false;
          }
        "
      >
        <div class="trigger" @click="toggleFilter(filterTypes.RECEIVED_AT)">
          Date <svg-sprite :width="20" :height="20" name="mt_arrow_down" />
        </div>
      </DatePicker>

      <div class="item" v-clickoutside="(e) => clickOutside(filterTypes.FROM, e)">
        <div class="trigger" :class="{ active: fromFilterOpen }" @click="toggleFilter(filterTypes.FROM)">
          From
          <svg-sprite :width="20" :height="20" name="mt_arrow_down" />
        </div>
        <div v-if="fromFilterOpen" class="popup">
          <div class="content">
            <Input
              ref="fromInputRef"
              class="input"
              type="text"
              v-model="filter.from"
              @on-enter="submitChange"
              @on-keydown="onInputKeyPress($event, filterTypes.FROM)"
            />
          </div>
          <div class="footer" @click="submitChange">Done</div>
        </div>
      </div>

      <div class="item" v-clickoutside="(e) => clickOutside(filterTypes.TO, e)">
        <div class="trigger" :class="{ active: toFilterOpen }" @click="toggleFilter(filterTypes.TO)">
          To <svg-sprite :width="20" :height="20" name="mt_arrow_down" />
        </div>
        <div v-if="toFilterOpen" class="popup">
          <div class="content">
            <Input
              ref="toInputRef"
              class="input"
              type="text"
              v-model="filter.to"
              @on-enter="submitChange"
              @on-keydown="onInputKeyPress($event, filterTypes.TO)"
            />
          </div>
          <div class="footer" @click="submitChange">Done</div>
        </div>
      </div>

      <div class="item" v-clickoutside="(e) => clickOutside(filterTypes.SUBJECT, e)">
        <div class="trigger" :class="{ active: subjectFilterOpen }" @click="toggleFilter(filterTypes.SUBJECT)">
          Subject <svg-sprite :width="20" :height="20" name="mt_arrow_down" />
        </div>
        <div v-if="subjectFilterOpen" class="popup">
          <div class="content">
            <Input
              ref="subjectInputRef"
              class="input"
              type="text"
              v-model="filter.subject"
              @on-enter="submitChange"
              @on-keydown="onInputKeyPress($event, filterTypes.SUBJECT)"
            />
          </div>
          <div class="footer" @click="submitChange">Done</div>
        </div>
      </div>

      <Select
        v-if="isShowAssignFilter"
        v-model="filter.assignees"
        class="select-no-border assignee-item"
        :class="{ active: assigneesFilterOpen }"
        transfer-class-name="mail-filter-assignment-select"
        clearable
        multiple
        :transfer="true"
        placeholder="Assignee"
        :max-tag-count="1"
        :max-tag-placeholder="(num) => '+' + num"
        @on-change="onAssignFilterChange($event)"
        @on-open-change="onAssignFilterOpen($event)"
        ref="assigneeRef"
      >
        <Option v-if="currentAssignTo !== assignedToEnum.FOCUSED" value="any">Any</Option>
        <Option value="none">None</Option>
        <Option v-if="currentAssignTo == assignedToEnum.FOCUSED" :value="userInfo.user.id">
          {{ userInfo.user.name || userInfo.user.user_name }}
        </Option>
        <Option v-for="member in teamMembers" :key="member.id" :value="member.id" :label="member.name">
          <Checkbox :value="filter.assignees && filter.assignees.includes(member.id)" />
          <span class="name text-ellipsis">{{ member.name || member.user_name }}</span>
        </Option>
      </Select>

      <div class="item" v-clickoutside="(e) => clickOutside(filterTypes.TAG, e)">
        <div class="trigger" :class="{ active: tagFilterOpen }" @click="toggleFilter(filterTypes.TAG)">
          Tag <svg-sprite :width="20" :height="20" name="mt_arrow_down" />
        </div>
        <div v-if="tagFilterOpen" class="popup">
          <div class="content">
            <Input
              ref="tagInputRef"
              class="input"
              type="text"
              v-model="filter.tag"
              @on-enter="submitChange"
              @on-keydown="onInputKeyPress($event, filterTypes.TAG)"
            />
          </div>
          <div class="footer" @click="submitChange">Done</div>
        </div>
      </div>

      <div class="item" v-if="$route.query.search !== 'advance'">
        <Checkbox v-model="filter.hasAttachments" @on-change="submitChange">
          <span class="checkbox-label">Has attachments</span>
        </Checkbox>
      </div>
    </div>

    <div class="selected-items">
      <div
        v-if="submitedFilter.date_shortcut || submitedFilter.received_at || submitedFilter.received_at_range"
        class="item"
        @click="toggleFilter(filterTypes.RECEIVED_AT)"
      >
        <template v-if="submitedFilter.date_shortcut">
          Date within {{ submitedFilter.date_shortcut.toLowerCase() }}
        </template>
        <template v-else-if="submitedFilter.received_at_range">
          Date from {{ submitedFilter.received_at_range[0] }} to
          {{ submitedFilter.received_at_range[1] }}
        </template>
        <template v-else> Date within {{ submitedFilter.received_at }} </template>
        <svg-sprite :width="14" :height="14" name="mt_cancel" @click.native="onRemove(filterTypes.RECEIVED_AT)" />
      </div>
      <div
        v-if="submitedFilter.from || filter.from"
        class="item"
        :class="{ unsend: !submitedFilter.from }"
        @click="toggleFilter(filterTypes.FROM)"
      >
        From contains: "{{ filter.from }}"
        <svg-sprite :width="14" :height="14" name="mt_cancel" @click.native="onRemove(filterTypes.FROM)" />
      </div>
      <div
        v-if="submitedFilter.to || filter.to"
        class="item"
        :class="{ unsend: !submitedFilter.to }"
        @click="toggleFilter(filterTypes.TO)"
      >
        To contains: "{{ filter.to }}"
        <svg-sprite :width="14" :height="14" name="mt_cancel" @click.native="onRemove(filterTypes.TO)" />
      </div>
      <div
        v-if="submitedFilter.subject || filter.subject"
        class="item"
        :class="{ unsend: !submitedFilter.subject }"
        @click="toggleFilter(filterTypes.SUBJECT)"
      >
        Subject contains: "{{ filter.subject }}"
        <svg-sprite :width="14" :height="14" name="mt_cancel" @click.native="onRemove(filterTypes.SUBJECT)" />
      </div>
      <div
        v-if="submitedFilter.assignees && submitedFilter.assignees.length > 0 && !isSpamList"
        class="item"
        @click="toggleFilter(filterTypes.ASSIGNEES)"
      >
        {{ selectedAssigneeText }}
        <svg-sprite :width="14" :height="14" name="mt_cancel" @click.native="onRemove(filterTypes.ASSIGNEES)" />
      </div>
      <div
        v-if="submitedFilter.tag || filter.tag"
        class="item"
        :class="{ unsend: !submitedFilter.tag }"
        @click="toggleFilter(filterTypes.TAG)"
      >
        Tag contains: "{{ filter.tag }}"
        <svg-sprite :width="14" :height="14" name="mt_cancel" @click.native="onRemove(filterTypes.TAG)" />
      </div>
    </div>
  </div>
</template>

<script>
import appConstant from "@/common/constants/app.constant";
import { startOfWeek, subWeeks, endOfWeek, format } from "date-fns";
import { mapGetters } from "vuex";
import clickoutside from "@/utils/clickoutside";
import { mailSearchMixin } from "@/mixins/index";

const dateShortcuts = {
  TODAY: "TODAY",
  YESTERDAY: "YESTERDAY",
  THIS_WEEK: "THIS WEEK",
  LAST_WEEK: "LAST WEEK",
};

export default {
  props: {
    currentAssignTo: String,
  },
  directives: { clickoutside },
  mixins: [mailSearchMixin],
  data() {
    return {
      assignedToEnum: appConstant.assignedTo,
      datePickerOptions: {
        shortcuts: [
          {
            text: "Today",
            onClick: (_) => this.onShortcutClick(dateShortcuts.TODAY),
          },
          {
            text: "Yesterday",
            onClick: (_) => this.onShortcutClick(dateShortcuts.YESTERDAY),
          },
          {
            text: "This week",
            onClick: (_) => this.onShortcutClick(dateShortcuts.THIS_WEEK),
          },
          {
            text: "Last week",
            onClick: (_) => this.onShortcutClick(dateShortcuts.LAST_WEEK),
          },
          {
            text: "Select range",
            onClick: this.onCustomShortcutClick,
          },
        ],
      },
      filterTypes: {
        RECEIVED_AT: "received_at",
        FROM: "from",
        TO: "to",
        SUBJECT: "subject",
        ASSIGNEES: "assignees",
        TAG: "tag",
      },
      filterInput: [],
      datePickerType: "date",

      received_atFilterOpen: false,
      fromFilterOpen: false,
      toFilterOpen: false,
      subjectFilterOpen: false,
      tagFilterOpen: false,
      assigneesFilterOpen: false,

      filter: {
        received_at: undefined,
        received_at_range: undefined,
        date_shortcut: undefined,
        from: undefined,
        to: undefined,
        mailbox: undefined,
        subject: undefined,
        assignees: [],
        tag: undefined,
        hasAttachments: false,
      },
      submitedFilter: {},
    };
  },
  created() {
    this.filterInput = [this.filterTypes.FROM, this.filterTypes.TO, this.filterTypes.SUBJECT, this.filterTypes.TAG];
  },
  computed: {
    ...mapGetters(["setting_company", "teamMembers", "userInfo", "setting_mails"]),
    isShowAssignFilter() {
      return (
        !this.isSpamList &&
        this.p_enabled_assignment &&
        ![this.assignedToEnum.ASSIGNED_TO_ME, this.assignedToEnum.UNASSIGNED].includes(this.currentAssignTo)
      );
    },
    isSpamList() {
      return this.$route_box() && this.$route_box().toLowerCase() === "spam";
    },
    p_enabled_assignment() {
      return this.setting_company.assignment_module == "1";
    },
    selectedAssigneeText() {
      if (!this.submitedFilter.assignees || this.submitedFilter.assignees.length === 0) return "";

      if (this.submitedFilter.assignees[0] === "none") return "No assignee";

      if (this.submitedFilter.assignees[0] === "any") return "Any assignee";

      return (
        "Assignees contains: " +
        this.submitedFilter.assignees
          .map((id) => {
            const member = this.teamMembers.find((m) => m.id === id);
            return member ? member.name || member.user_name : "";
          })
          .join(", ")
      );
    },
  },
  methods: {
    onInputKeyPress(event, type) {
      if (event.key === "Tab") {
        event.preventDefault();
        const currentIndex = this.filterInput.indexOf(type);
        console.log(currentIndex);
        if (currentIndex === -1) return;

        this.toggleFilter(this.filterInput[(currentIndex + 1) % this.filterInput.length]);
      }
    },
    toggleFilter(type) {
      this.closeOtherPopups(type);
      switch (type) {
        case this.filterTypes.RECEIVED_AT:
          this.received_atFilterOpen = !this.received_atFilterOpen;
          break;
        case this.filterTypes.FROM:
        case this.filterTypes.TO:
        case this.filterTypes.SUBJECT:
        case this.filterTypes.TAG:
          this[`${type}FilterOpen`] = !this[`${type}FilterOpen`];
          if (this[`${type}FilterOpen`]) {
            this.$nextTick((_) => {
              this.$refs[`${type}InputRef`] && this.$refs[`${type}InputRef`].focus();
            });
          }
          break;
        case this.filterTypes.ASSIGNEES:
          this.$nextTick((_) => {
            this.$refs.assigneeRef.toggleMenu();
          });
          break;
      }
    },
    onShortcutClick(type) {
      this.filter.date_shortcut = type;
      this.filter.received_at = undefined;
      this.filter.received_at_range = undefined;
      let start, end;
      switch (type) {
        case dateShortcuts.TODAY:
          this.filter.received_at = format(new Date(), "yyyy-MM-dd");
          break;
        case dateShortcuts.YESTERDAY:
          let date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          this.filter.received_at = format(date, "yyyy-MM-dd");
          break;
        case dateShortcuts.THIS_WEEK:
          start = startOfWeek(new Date(), { weekStartsOn: 1 });
          break;
        case dateShortcuts.LAST_WEEK:
          const dateOfWeekAgo = subWeeks(new Date(), 1);
          start = startOfWeek(dateOfWeekAgo, { weekStartsOn: 1 });
          break;
      }
      if (type === dateShortcuts.THIS_WEEK || type === dateShortcuts.LAST_WEEK) {
        start = format(start, "yyyy-MM-dd");
        end = endOfWeek(new Date(start), { weekStartsOn: 1 });
        end = format(end, "yyyy-MM-dd");
        this.filter.received_at_range = [start, end];
      }
      this.submitChange();
    },
    onCustomShortcutClick() {
      this.datePickerType = this.datePickerType === "daterange" ? "date" : "daterange";
      this.$nextTick((_) => {
        if (!this.$refs.datePickerRef) return;
        this.$refs.datePickerRef.handleClear();
        const text = this.datePickerType === "daterange" ? "Select date" : "Select range";
        this.$refs.datePickerRef.options &&
          this.$refs.datePickerRef.options.shortcuts[4] &&
          (this.$refs.datePickerRef.options.shortcuts[4].text = text);
      });
    },
    onDateFilterChange(date) {
      if (!date) return;

      if (Array.isArray(date) && (!date[0] || !date[1])) return;

      this.filter.received_at = undefined;
      this.filter.received_at_range = undefined;
      this.filter.date_shortcut = undefined;

      if (Array.isArray(date)) {
        this.filter.received_at_range = date;
        this.submitChange();
        return;
      }

      this.filter.received_at = date;
      this.submitChange();
    },
    onAssignFilterChange(selectedItems) {
      let self = this;
      const selectedCount = selectedItems.length;
      const selected = selectedItems[selectedItems.length - 1];
      if (selected == "any" || selected == "none") {
        this.filter.assignees.splice(0, selectedItems.length - 1);
        applyFilter();
        return;
      }
      this.filter.assignees = selectedItems.filter((u) => u != "any" && u != "none");
      applyFilter();

      function applyFilter() {
        selectedCount == self.filter.assignees.length && self.submitChange();
      }
    },
    onAssignFilterOpen(open) {
      this.assigneesFilterOpen = open;
      open && this.closeOtherPopups(this.filterTypes.ASSIGNEES);
    },
    onRemove(type) {
      this.filter[type] = undefined;
      if (type == this.filterTypes.RECEIVED_AT) {
        this.filter.date_shortcut = undefined;
        this.filter.received_at_range = undefined;
      }
      this.submitChange();
    },
    submitChange() {
      this.$emit("on-change", {
        ...this.filter,
        from: this.filter.from ? this.applySearchMethods(this.filter.from) : this.filter.from,
        to: this.filter.to ? this.applySearchMethods(this.filter.to) : this.filter.to,
        subject: this.filter.subject ? this.applySearchMethods(this.filter.subject) : this.filter.subject,
      });
      this.submitedFilter = { ...this.filter };
      this.closePopup();
    },
    closePopup() {
      this.received_atFilterOpen =
        this.fromFilterOpen =
        this.toFilterOpen =
        this.subjectFilterOpen =
        this.tagFilterOpen =
          false;
    },
    closeOtherPopups(type) {
      const types = Object.values(this.filterTypes);
      if (!types || types.length === 0) return;
      types.forEach((k) => {
        k !== type && (this[`${k}FilterOpen`] = false);
      });
    },
    clickOutside(type, e) {
      if (e && e.target && (e.target.closest(".mail-filter-icon") || e.target.closest(".selected-items .item"))) return;
      this[`${type}FilterOpen`] = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-label {
  margin-left: 4px;
  font-size: 13px;
}
.mail-filter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 16px;
  gap: 4px;
  background-color: var(--surface-color);
  .filter-items {
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;
    .item {
      position: relative;
    }
    .trigger {
      display: flex;
      align-items: center;
      padding: 6px 8px;
      border-radius: 4px;
      font-size: 13px;
      gap: 2px;
      &:hover {
        background-color: var(--hover-color);
        cursor: pointer;
      }
    }
    .popup {
      position: absolute;
      top: 35px;
      z-index: 105;
      width: 250px;
      padding: 8px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      font-size: 13px;
      background-color: var(--component-color);
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14);
      box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
      .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 0;
        margin-top: 8px;
        color: var(--blue-main);
        font-weight: bold;
        &:hover {
          cursor: pointer;
          background-color: var(--hover-color);
        }
      }
    }
    .assignee-item {
      width: auto;
      &:hover {
        background-color: var(--hover-color);
        border-radius: 4px;
      }
    }
    .trigger,
    .date-picker,
    .assignee-item {
      &.active {
        background-color: var(--hover-color);
      }
    }
  }
  .selected-items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    font-size: 13px;
    .item {
      position: relative;
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 2px 28px 2px 12px;
      border-radius: 16px;
      background-color: var(--primary-color);
      color: var(--neutral);
      cursor: pointer;
      svg {
        position: absolute;
        right: 4px;
        fill: var(--neutral);
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
      &.unsend {
        opacity: 0.7;
      }
    }
  }
}
</style>
<style>
.mail-filter-assignment-select {
  min-width: 250px !important;
  span.name {
    margin-right: 16px !important;
  }
}
.mail-filter {
  .ivu-poptip-arrow {
    display: none;
  }
  .assignee-item {
    .ivu-select-placeholder,
    i {
      color: var(--text-color) !important;
    }
    .ivu-select-placeholder {
      padding-right: 8px !important;
    }
  }
}
</style>
