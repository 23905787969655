const Login = () => import("@/pages/Login");
const login2FA = () => import("@/pages/Login2FA.vue");
const LoginSSO = () => import("@/pages/LoginSSO.vue");
const ResetPass = () => import("@/pages/ResetPass.vue");
//-----------------------------------mails
import Mails from "@/pages/mails/Mails";
const Mails_nav = () => import("@/pages/mails/nav/nav.vue");
const Notifications = () => import("@/pages/mails/Notifications");
const ReleaseNote = () => import("@/pages/mails/ReleaseNote");
const mailDetail = () => import("../pages/mails/detail/mailDetail.vue");
const mailDetailWindow = () => import("../pages/mails/detail/mailDetailWindow.vue");
const Print = () => import("../pages/mails/Print.vue");
const viewer = () => import("../pages/mails/docViewer/viewer.vue");
//-----------------------------------original message
const originalMsg = () => import("../pages/mails/originalMsg.vue");
//-----------------------------------send mail
const sendMailWindow = () => import("../pages/sendMail/sendMail.vue");
//-----------------------------------ships
const ships = () => import("../pages/ships/nav/nav.vue");
import shipping from "../pages/ships/shipping/shipping.vue";
const positionView = () => import("../pages/ships/shipping/positionView.vue");
const addShip = () => import("../pages/ships/add/addShips.vue");
const shipDetailWindow = () => import("../pages/ships/detail/window/detailWindow.vue");
//------------------------------------contact
const Contacts = () => import("../pages/contact/nav/nav.vue");
const addCompany = () => import("../pages/contact/add/addCompany.vue");
const addContact = () => import("../pages/contact/add/addContact.vue");
const addDepartment = () => import("../pages/contact/add/addDepartment.vue");
const shares = () => import("../pages/contact/shares/shares.vue");
const contactDetailWindow = () => import("../pages/contact/detail/window/detailWindow.vue");
//------------------------------------calendar
import Calendar from "../pages/calendar/nav/nav.vue";
const CalendarTable = () => import("../pages/calendar/CalendarTable.vue");
//------------------------------------setting
const set = () => import("../pages/setting/nav/nav.vue");
const profile = () => import("../pages/setting/profile/profile.vue");
const mailAccounts = () => import("../pages/setting/mailAccounts/mailAccounts.vue");
const addEmailAccount = () => import("../pages/setting/mailAccounts/addEmailAccount.vue");
const subscriptions = () => import("../pages/setting/subscriptions/Subscriptions.vue");
const preferences = () => import("../pages/setting/preferences/preferences.vue");
const UsersRoles = () => import("../pages/setting/Users & Roles/UsersRoles.vue");
const Users = () => import("../pages/setting/Users & Roles/Users.vue");
const UserDetail = () => import("../pages/setting/Users & Roles/UserDetail.vue");
const Roles = () => import("../pages/setting/Users & Roles/roles.vue");
const NewRole = () => import("../pages/setting/Users & Roles/newRole.vue");
const EditRole = () => import("../pages/setting/Users & Roles/editRole.vue");
const completeInvitation = () => import("../pages/setting/Users & Roles/completeInvitation.vue");
const viewAllShip = () => import("../pages/ships/shipping/allShip.vue");
const notificationSetting = () => import("../pages/setting/notification/notification-setting.vue");
const MailParsing = () => import("../pages/setting/mailParsing/MailParsing.vue");

const impersonationAccounts = () => import("../pages/setting/impersonationAccounts/impersonationAccounts.vue");
const createImpersonationAccount = () =>
  import("../pages/setting/impersonationAccounts/createImpersonationAccount.vue");
const impersonationAccountSetting = () =>
  import("../pages/setting/impersonationAccounts/impersonationAccountSetting.vue");
const googleAccounts = () => import("../pages/setting/googleAccounts/googleAccounts.vue");
const createGoogleAccount = () => import("../pages/setting/googleAccounts/createGoogleAccount.vue");
const googleAccountSetting = () => import("../pages/setting/googleAccounts/googleAccountSetting.vue");
const microsoftAccounts = () => import("../pages/setting/microsoftAccounts/microsoftAccounts.vue");
const changePassword = () => import("../pages/setting/changePassword/changePassword.vue");
const companySettings = () => import("../pages/setting/companySettings/companySettings.vue");
const ruleSettings = () => import("../pages/setting/ruleSettings/ruleSettings.vue");
const tagsSetting = () => import("../pages/setting/tagsSetting/tagsSetting.vue");
const ssoSetting = () => import("../pages/setting/ssoSetting/SSOSetting.vue");

//---------------Open position------------
const OpenPositions = () => import("@/pages/mails/openPosition/OpenPositions.vue");

import util from "util";
import storageConstant from "@/common/constants/local-storage.constant";
import appConstant from "../common/constants/app.constant";

let routes = [
  {
    path: "/oauth/callback",
    name: "Oauth Callback",
    component: Login,
    meta: {
      title: "Oauth Callback",
    },
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: Login,
  //   hidden: true,
  //   meta: {
  //     title: "Login",
  //   },
  // },
  // {
  //   path: "/loginSSO",
  //   name: "loginSSO",
  //   component: LoginSSO,
  //   meta: {
  //     title: "Login with SSO",
  //   },
  // },
  {
    path: "/resetPassword",
    name: "reset",
    component: ResetPass,
    meta: {
      title: "Reset Password",
    },
  },
  // {
  //   path: "/two-factor-verification/:verification",
  //   name: "Login2FA",
  //   component: login2FA,
  //   props: true,
  //   meta: {
  //     title: "Two-Factor Authentication",
  //   },
  // },
  {
    path: "/",
    name: "Mails",
    component: Mails_nav,
    redirect: "/mails",
    children: [
      {
        path: "/mails",
        name: "mail",
        component: Mails,
        meta: {
          keepAlive: true,
          title: (route) => {
            if (!route.query) return appConstant.pageNames.MAIL_ACTIVITY;

            let title = route.query.me
              ? "Mentions"
              : route.query.is_star
              ? "Starred Mail"
              : route.query.box && route.query.status == "pending"
              ? "Outbox"
              : route.query.box
              ? util.capitalizeFirstLetter(route.query.box)
              : route.query.status == "sent"
              ? "Sent"
              : route.query.archived
              ? "Archive"
              : route.query.folder_id && route.query.folder_name
              ? `${route.query.folder_name}`
              : appConstant.pageNames.MAIL_ACTIVITY;

            if (route.query.line_id) {
              const lines = util.storeInLocalStorage.get(storageConstant.LINES);
              const line = lines && lines.find((l) => l.id == route.query.line_id);
              line && (title += ` (${line.username})`);
            }

            return title;
          },
        },
      },
      {
        path: "/mailDetail/:id",
        name: "mailDetail",
        component: mailDetail,
      },
      {
        path: "/notifications",
        name: "notifications",
        component: Notifications,
        meta: {
          title: "Notifications",
        },
      },
      {
        path: "/releaseNotes",
        name: "releaseNotes",
        component: ReleaseNote,
        meta: {
          title: "Release Notes",
        },
      },
      {
        path: "/openPositions",
        name: "openPositions",
        component: OpenPositions,
        meta: {
          title: "Open Positions",
        },
      },
    ],
  },
  {
    path: "/mail/:id",
    name: "mailDetailWindow",
    component: mailDetailWindow,
  },
  {
    path: "/mail/previewEml/:id",
    name: "previewEmlWindow",
    component: mailDetailWindow,
  },
  //----------------------------original message
  {
    path: "/originalMail/:id",
    name: "originalMessage",
    component: originalMsg,
    meta: {
      title: "Original Mail",
    },
  },
  //----------------------------------send mail
  {
    path: "/sendMail",
    name: "sendMailWindow",
    component: sendMailWindow,
    meta: {
      title: "Compose mail",
    },
  },
  //----------------------------------contacts
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
    meta: {
      title: (route) => {
        const user = util.storeInLocalStorage.get(storageConstant.USER);
        const companyName = `Contacts - ${user && user.company && user.company.name}` || "Contacts";
        if (!route.query) return companyName;
        return route.query.me
          ? "Contacts - @Mentions"
          : route.query.name
          ? `Contacts - ${route.query.name}`
          : companyName;
      },
    },
    children: [
      {
        path: "/contacts/addContact",
        name: "addContact",
        component: addContact,
        meta: {
          title: "Add Person",
        },
      },
      {
        path: "/contacts/editContact/:id",
        name: "editContact",
        component: addContact,
        meta: {
          title: "Edit Person",
        },
      },
      {
        path: "/contacts/addCompany",
        name: "addCompany",
        component: addCompany,
        meta: {
          title: "Add Company",
        },
      },
      {
        path: "/contacts/editCompany/:id",
        name: "editCompany",
        component: addCompany,
        meta: {
          title: "Edit Company",
        },
      },
      {
        path: "/contacts/addDepartment",
        name: "addDepartment",
        component: addDepartment,
        meta: {
          title: "Add Department",
        },
      },
      {
        path: "/contacts/editDepartment/:id",
        name: "editDepartment",
        component: addDepartment,
        meta: {
          title: "Edit Department",
        },
      },
    ],
  },
  //----------------------------------calendar
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar,
    meta: {
      title: "Calendar",
    },
    children: [
      {
        path: "/calendar/table",
        name: "CalendarTable",
        component: CalendarTable,
        meta: {
          title: "Calendar",
        },
      },
    ],
  },
  {
    path: "/contactDetail/:id",
    name: "contactDetailWindow",
    component: contactDetailWindow,
  }, //窗口页面
  //----------------------------------ships
  {
    path: "/ships",
    name: "ships",
    component: ships,
    redirect: {
      name: "shipping",
    },
    children: [
      {
        path: "/ships/shipping",
        name: "shipping",
        component: shipping,
        meta: {
          title: (route) => {
            const user = util.storeInLocalStorage.get(storageConstant.USER);
            const companyName = `Ships - ${user && user.company && user.company.name}` || "Ships";
            if (!route.query) return companyName;

            return route.query.me
              ? "Ships - @Mentions"
              : route.query.name
              ? `Ships - ${route.query.name}`
              : companyName;
          },
        },
      },
      {
        path: "/ships/shipping/:id",
        name: "shipGroup",
        component: shipping,
      },
      {
        path: "/ships/positionView",
        name: "positionView",
        component: positionView,
        meta: {
          title: (route) => {
            const user = util.storeInLocalStorage.get(storageConstant.USER);
            return `Ships - ${user && user.company && user.company.name}` || "Position View";
          },
        },
      },
      {
        path: "/ships/addShip",
        name: "addShip",
        component: addShip,
        meta: {
          title: "Add new ship",
        },
      },
      {
        path: "/ships/addShip/:id",
        name: "editShip",
        component: addShip,
        meta: {
          title: "Edit Ship",
        },
      },
    ],
  },
  {
    path: "/shipDetail/:id",
    name: "shipDetailWindow",
    component: shipDetailWindow,
  },
  {
    path: "/allShip/:id/:name",
    name: "allShip",
    component: viewAllShip,
    meta: {
      title: (route) => (route.params && route.params.name) || "All Ships",
    },
  },
  //----------------------------------setting
  {
    path: "/set",
    name: "set",
    component: set,
    children: [
      {
        path: "/profile",
        name: "profile",
        component: profile,
        meta: {
          title: "Profile",
        },
      },
      {
        path: "/MailAccounts",
        name: "MailAccounts",
        component: mailAccounts,
        meta: {
          title: "Mailboxes",
        },
        children: [
          {
            path: "/MailAccounts/:owner/:email",
            name: "addEmailAccount",
            component: addEmailAccount,
            meta: {
              title: "Add Mailbox",
            },
          },
          {
            path: "/MailAccounts/:id",
            name: "mailAccountSetting",
            component: addEmailAccount,
            meta: {
              title: "Mailboxes",
            },
          },
        ],
      },
      {
        path: "/subscriptions",
        name: "subscriptions",
        component: subscriptions,
      },
      {
        path: "/impersonationAccounts",
        name: "impersonationAccounts",
        component: impersonationAccounts,
        meta: {
          title: "Impersonation Accounts",
        },
        children: [
          {
            path: "/impersonationAccounts/create",
            name: "createImpersonationAccount",
            component: createImpersonationAccount,
            meta: {
              title: "Add Impersonation Account",
            },
          },
          {
            path: "/impersonationAccounts/:id",
            name: "impersonationAccountSetting",
            component: impersonationAccountSetting,
            meta: {
              title: "Impersonation Accounts",
            },
          },
        ],
      },
      {
        path: "/microsoftAccounts",
        name: "microsoftAccounts",
        component: microsoftAccounts,
        meta: {
          title: "Microsoft Accounts",
        },
      },
      {
        path: "/googleAccounts",
        name: "googleAccounts",
        component: googleAccounts,
        meta: {
          title: "Google Accounts",
        },
        children: [
          {
            path: "/googleAccounts/create",
            name: "createGoogleAccount",
            component: createGoogleAccount,
            meta: {
              title: "Add Google Account",
            },
          },
          {
            path: "/googleAccounts/:id",
            name: "googleAccountSetting",
            component: googleAccountSetting,
            meta: {
              title: "Google Accounts",
            },
          },
        ],
      },
      {
        path: "/preferences",
        name: "preferences",
        component: preferences,
        meta: {
          title: "Preferences",
        },
      },
      {
        path: "/UsersRoles",
        name: "UsersRoles",
        component: UsersRoles,
        redirect: "/UsersRoles/users",
        children: [
          {
            path: "/UsersRoles/users",
            name: "users",
            component: Users,
            meta: {
              title: "Users & Roles",
            },
            children: [
              {
                path: "/UsersRoles/users/add",
                name: "addUser",
                component: UserDetail,
                meta: {
                  title: "Add user",
                },
              },
              {
                path: "/UsersRoles/users/:id",
                name: "editUser",
                component: UserDetail,
                meta: {
                  title: "Edit user",
                },
              },
            ],
          },
          {
            path: "/UsersRoles/roles",
            name: "roles",
            component: Roles,
            meta: {
              title: "Users & Roles",
            },
            children: [
              {
                path: "/UsersRoles/roles/new",
                name: "newRole",
                component: NewRole,
                meta: {
                  title: "Add Role",
                },
              },
              {
                path: "/UsersRoles/roles/:id",
                name: "editRole",
                component: EditRole,
                meta: {
                  title: "Edit Role",
                },
              },
            ],
          },
        ],
      },
      {
        path: "/changePassword",
        name: "changePassword",
        component: changePassword,
        meta: {
          title: "Change Password",
        },
      },
      {
        path: "/companySettings",
        name: "companySettings",
        component: companySettings,
        meta: {
          title: "Company Setting",
        },
      },
      {
        path: "/ruleSettings",
        name: "ruleSettings",
        component: ruleSettings,
        meta: {
          title: "Rules Setting",
        },
      },
      {
        path: "/tagsSetting",
        name: "tagsSetting",
        component: tagsSetting,
        meta: {
          title: "Tags Setting",
        },
      },
      {
        path: "/notification-setting",
        name: "notification-setting",
        component: notificationSetting,
        meta: {
          title: "Notification Setting",
        },
      },
      {
        path: "/mailParsing",
        name: "mailPasing",
        component: MailParsing,
        meta: {
          title: "Mail Parsing",
        },
      },
      {
        path: "/ssoSetting",
        name: "ssoSetting",
        component: ssoSetting,
        meta: {
          title: "SSO Setting",
        },
      },
    ],
  },
  //--------------完善邀请（注册）
  {
    path: "/completeInvitation",
    name: "completeInvitation",
    component: completeInvitation,
    meta: {
      title: "Complete Invitation",
    },
  },
  {
    path: "/shares",
    name: "shares",
    component: shares,
    meta: {
      title: "Shares",
    },
  },
  {
    path: "/printMail/:id",
    name: "print",
    component: Print,
    meta: {
      title: "Print",
    },
  },
  //----------------------------附件预览
  {
    path: "/viewer",
    name: "viewer",
    component: viewer,
    meta: {
      title: "Viewer",
    },
  },
  {
    path: "*",
    redirect: "/mails",
  },
];

export default routes;
