import { render, staticRenderFns } from "./VerticalMenu.vue?vue&type=template&id=143e1746&scoped=true"
import script from "./VerticalMenu.vue?vue&type=script&lang=js"
export * from "./VerticalMenu.vue?vue&type=script&lang=js"
import style0 from "./VerticalMenu.vue?vue&type=style&index=0&id=143e1746&prod&scoped=true&lang=scss"
import style1 from "./VerticalMenu.vue?vue&type=style&index=1&id=143e1746&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "143e1746",
  null
  
)

export default component.exports