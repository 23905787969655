<template>
  <!-- eslint-disable -->
  <div :class="`vertial-menu enabled-animation ${basicData.pinnedMenu ? 'pined-menu' : 'unpined-menu'}`">
    <div class="logo">
      <div class="logo-img" @click="$router.push('/mails')">
        <svg-sprite fill="#fff" name="mt_logo" :width="28" :height="28" />
      </div>
      <div class="logo-wordmark" @click="$router.push('/mails')">
        <svg-sprite fill="#fff" name="mt_logo_wordmark_one_color" :width="134" :height="32" />
      </div>
    </div>
    <div class="vertical-top-menu">
      <!-- <Tooltip placement="top" content="Mails"> -->
      <router-link :to="mailRouterPath" :class="{ 'router-link-active': $route.name === 'mail' }">
        <div class="menu-icon">
          <svg-sprite fill="#fff" :width="18" :height="18" name="mt_mail" />
        </div>
        <span class="menu-text">Mail</span>
      </router-link>
      <!-- </Tooltip> -->
      <router-link :to="{ name: 'ships' }" v-if="$store.getters.setting_company.shipping_module_turn_off == '0'">
        <div class="menu-icon">
          <svg-sprite fill="#fff" :width="18" :height="18" name="mt_ship" />
        </div>
        <span class="menu-text">Ships</span>
      </router-link>

      <router-link :to="{ name: 'Contacts' }">
        <div class="menu-icon">
          <svg-sprite fill="#fff" :width="18" :height="18" name="mt_contact" />
        </div>
        <span class="menu-text">Contacts</span>
      </router-link>

      <router-link :to="{ name: 'CalendarTable' }" v-if="$store.getters.setting_company.calendar_module == '1'">
        <div class="menu-icon">
          <svg-sprite fill="#fff" :width="18" :height="18" name="mt_calendar" />
        </div>
        <span class="menu-text">Calendar</span>
      </router-link>
    </div>
    <div class="spacer"></div>
    <div class="vertical-bottom-menu">
      <div class="menu-item" @click="handleOpenChangesLog">
        <div class="menu-icon">
          <svg-sprite fill="#fff" :width="18" :height="18" name="mt_change_log" />
        </div>
        <span class="menu-text">Release Notes</span>
      </div>
      <Poptip
        ref="notifPop"
        trigger="hover"
        class="ch-topfunc-item"
        placement="right-end"
        v-model="notificationPoptipVisible"
        width="335"
        @on-popper-show="onPopperShow"
      >
        <div class="menu-item">
          <div class="menu-icon">
            <svg-sprite
              fill="#fff"
              :width="18"
              :height="18"
              :name="notificationUnreadCount ? 'mt_announcement_with_red_dot' : 'mt_announcement'"
            />
          </div>
          <span class="menu-text">Notification</span>
          <div v-if="notificationUnreadCount" class="message-count announcement-count">
            {{ notificationUnreadCount }}
          </div>
        </div>
        <div class="notification-container" slot="content" v-if="notificationPoptipVisible">
          <header>
            <h2 class="title">Notifications</h2>
          </header>
          <ul v-if="formattedNotifications && formattedNotifications.length > 0" class="notification-list">
            <li
              v-for="(notification, index) in formattedNotifications"
              v-bind:key="index"
              class="notification-item"
              @click="onSelectNotification(notification)"
            >
              <avatar
                v-if="notification.from"
                class="avatar"
                :fullname="notification.from.name"
                :avatarURL="notification.from.avatar"
              ></avatar>
              <Icon class="icon-task" v-else-if="notification.noticeable_type == 'Task'" type="md-checkbox-outline" />
              <div class="content-container">
                <div class="content" v-html="sanitizeTextHTML(notification.html)"></div>
                <time>{{ notification.relativeTime }}</time>
              </div>
            </li>
          </ul>
          <div v-else class="nonotification">
            <span>There is no new notification</span>
          </div>
          <footer @click="handleShowAllNotifications">
            <h2 class="title">Show all notifications</h2>
          </footer>
        </div>
      </Poptip>
      <div class="menu-item" @click="openZohoChat">
        <div class="menu-icon">
          <Badge dot v-if="helpCenter.hasNewVersion">
            <svg-sprite fill="#fff" :width="18" :height="18" name="mt_help" />
          </Badge>
          <svg-sprite v-else fill="#fff" :width="18" :height="18" name="mt_help" />
        </div>
        <span class="menu-text">Help Center</span>
      </div>
      <Poptip ref="head-pop" trigger="hover" placement="right-end" v-model="userPoptipVisible">
        <div class="menu-item">
          <div class="menu-icon">
            <svg-sprite fill="#fff" :width="18" :height="18" name="mt_user" />
          </div>
          <span class="menu-text">My Account</span>
        </div>
        <div class="popcontent" slot="content" v-if="userPoptipVisible">
          <ul>
            <li class="fullname">
              <avatar class="avatar" :fullname="userInfo.user.name" :avatarURL="userInfo.user.avatar" :width="30">
              </avatar>
              <span>{{ userInfo.user.name }}</span>
            </li>
            <li @click="handleNavigateToProfile"><i class="iconfont-settings"></i>Settings</li>
            <Dropdown placement="left-start" class="themes" :trigger="'click'" :stop-propagation="true">
              <li><Icon type="md-color-fill" />Themes</li>
              <DropdownMenu slot="list">
                <DropdownItem @click.native="switchTheme(themes.ORIGINAL)">
                  <i class="iconfont-theme original" />Light
                </DropdownItem>
                <DropdownItem @click.native="switchTheme(themes.TWILIGHT)">
                  <i class="iconfont-theme twilight" />Dark
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <li @click="onLogout"><i class="iconfont ch-icon-poweroff"></i>Log out</li>
          </ul>
        </div>
      </Poptip>
      <div class="menu-item" @click="togglePinMenu">
        <div class="menu-icon">
          <svg-sprite fill="#fff" :width="18" :height="18" name="mt_pin_menu" />
        </div>
        <span class="menu-text">{{ basicData.pinnedMenu ? "Unpin" : "Pin" }} Menu</span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations } from "vuex";
import api from "api";
import util from "util";

import appConstant from "@/common/constants/app.constant";
import avatar from "@/pages/components/avatar.vue";
import popover from "@/pages/components/Popover.vue";
import { isEmpty } from "lodash";

export default {
  data() {
    return {
      notificationPoptipVisible: false,
      userPoptipVisible: false,
      themes: appConstant.themeNames,
    };
  },
  mounted() {
    setTimeout(async () => {
      const token = await this.$auth.getTokenSilently();
      if (!token) return;

      this.getNotifications({ is_read: false });
    }, 1000);
  },
  computed: {
    ...mapGetters([
      "notificationUnreadCount",
      "notifications",
      "formattedNotifications",
      "userInfo",
      "basicData",
      "helpCenter",
      "mailPath",
    ]),
    mailRouterPath() {
      return isEmpty(this.mailPath) ? { name: "mail" } : this.mailPath;
    },
  },
  methods: {
    ...mapMutations(["setNotifications", "SET_LOGGING_OUT", "TOGGLE_PIN_MENU"]),
    ...mapActions([
      "getNotifications",
      "readNotification",
      "showNotifDetails",
      "setTheme",
      "updateUserHelpCenterVersion",
    ]),
    onSelectNotification(notification) {
      this.showNotifDetails(notification);
      this.$Toastr.dispatch("close", notification.content);
    },
    openZohoChat() {
      ZohoHCAsapReady(function () {
        ZohoHCAsap.Action("open");
      });
      this.helpCenter.hasNewVersion && this.updateUserHelpCenterVersion();
    },
    onPopperShow() {
      if (this.formattedNotifications && this.notificationUnreadCount != this.formattedNotifications.length) {
        this.notificationUnreadCount == 0 && this.setNotifications([]);
        this.getNotifications();
      }
    },
    handleShowAllNotifications() {
      this.notificationPoptipVisible = false;
      this.$router.push("/notifications");
    },
    switchTheme(themeName) {
      this.$refs["head-pop"].isOpen = false;
      this.setTheme(themeName);
    },
    onLogout() {
      const needConfirmPages = ["preferences", "addUser", "editUser"];
      if (needConfirmPages.findIndex((p) => p == this.$route.name) > -1) {
        this.SET_LOGGING_OUT(true);
        return;
      }
      this.handleLogout();
    },
    handleNavigateToProfile() {
      this.$router.push("/profile");
      this.userPoptipVisible = false;
    },
    togglePinMenu() {
      this.TOGGLE_PIN_MENU();
    },
    handleOpenChangesLog() {
      this.$router.push("/releaseNotes");
    },
  },
  components: {
    avatar,
    popover,
  },
};
</script>

<style scoped lang="scss">
.vertial-menu {
  background-color: var(--primary-color-dark);
  width: 40px;
  height: 100%;
  padding: 5px;
  border-right: 4px solid #1c65d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.3s;
  svg {
    fill: #fff;
  }

  .logo {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;
  }

  .vertical-top-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 8px;

    a {
      position: relative;
      display: flex;
      gap: 8px;
      align-items: center;
      border-radius: 4px;
      user-select: none;
      overflow: hidden;

      &:hover {
        background-color: var(--primary-color-hover);
      }
    }

    a.router-link-active {
      background-color: var(--blue-main);
    }
  }

  .spacer {
    flex: 1;
  }

  .vertical-bottom-menu {
    width: 100%;
    flex: none;
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 8px;
    margin: 8px 0;

    .menu-item {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      border-radius: 4px;
      user-select: none;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        background-color: var(--primary-color-hover);
      }
    }
  }

  .announcement-count {
    position: absolute;
    right: 10px;
    visibility: hidden;
    transition: visibility 0s;
  }

  .menu-icon {
    position: relative;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-text {
    position: absolute;
    left: 26px;
    color: #fff;
    font-size: 13px;
    min-width: 100px;
  }

  .notification-container {
    .title {
      font-size: 14px;
      font-weight: bold;
      color: var(--primary-color);
      text-align: center;
    }

    header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      border-bottom: 1px solid var(--border-color);

      i.iconfont {
        position: absolute;
        right: 12px;
        font-size: 18px;
      }
    }

    .nonotification {
      display: flex;
      height: 50px;
      justify-content: center;
      align-items: center;
    }

    ul.notification-list {
      max-height: 300px;
      overflow: auto;
      overflow: overlay;

      li:not(:first-child) {
        border-top: 1px solid var(--border-color);
      }

      li.notification-item {
        display: flex;
        word-break: break-all;
        word-wrap: break-word;
        white-space: normal;
        padding: 16px;
        cursor: pointer;
        background-color: var(--component-color);
        color: var(--on-component-color);

        &:hover {
          background-color: var(--hover-color);
          color: var(--text-color);
        }

        .avatar {
          position: relative;
          display: inline-block;
          margin-right: 16px;
        }

        .icon-task {
          margin-right: 16px;
          font-size: 32px;
        }

        .content-container {
          display: flex;
          flex-direction: column;
          flex: 1;
          word-break: break-all;
          margin-left: 0;

          .content {
            min-width: 0;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.43;
            text-align: left;

            strong {
              font-weight: bold;
            }
          }

          time {
            margin-top: 8px;
            font-size: 12px;
            line-height: 1.58;
            text-align: left;
            color: rgb(130, 141, 157);
          }
        }
      }
    }

    footer {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      text-align: center;
      cursor: pointer;
      border-top: 1px solid var(--border-color);

      &:hover {
        background-color: var(--hover-color);
      }
    }
  }

  .popcontent {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        padding: 12px;
        cursor: pointer;

        i {
          margin-right: 8px;
        }

        .ivu-icon {
          font-size: 20px;
        }

        &:hover {
          background-color: var(--hover-color);
        }

        &.fullname {
          display: flex;
          align-items: center;
          font-size: 13px;
          font-weight: bold;
          border-bottom: 1px solid var(--border-color);
          padding: 8px 16px;

          &:hover {
            background: var(--component-color);
            color: var(--on-component-color);
            cursor: default;
          }

          .avatar {
            display: inline-block;
            margin-right: 8px;
          }
        }
      }

      .themes {
        width: 180px;

        .original {
          color: #7898b1;
        }

        .twilight {
          color: #000;
        }
      }
    }

    .iconfont {
      vertical-align: middle;
    }
  }
}

.unpined-menu {
  .logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      position: absolute;
      display: flex;
    }
  }
  .logo-img {
    transition: visibility 0s;
  }
  .logo-wordmark {
    visibility: hidden;
    transition: all 0s;
  }

  .menu-text {
    visibility: hidden;
    transition: visibility 0s;
  }
}

.unpined-menu:hover {
  transition-delay: 0.6s;
}

.unpined-menu:hover,
.pined-menu {
  width: 180px;

  .logo > div {
    transition-delay: 0.6s;
    &.logo-img {
      visibility: hidden;
    }

    &.logo-wordmark {
      visibility: visible;
    }
  }

  .menu-text {
    visibility: visible;
    transition-delay: 0.6s;
  }

  .announcement-count {
    display: flex;
    visibility: visible;
    transition-delay: 0.6s;
  }
}
</style>

<style lang="scss">
.vertical-bottom-menu {
  .ivu-poptip-rel {
    width: 100%;
  }

  .ivu-badge-dot {
    top: 0px;
    right: -2px;
    width: 5px;
    height: 5px;
    border: none;
    box-shadow: none;
  }

  .ivu-poptip-body {
    padding: 0;
  }
}
</style>
