import util from 'util'
import api, { getBaseUrl } from 'api'
import Cookies from 'js-cookie'
import {
  SET_USER,
  SET_TOKEN,
  SET_TEAM_MEMBERS,
  SET_DEACTIVATED_TEAM_MEMBERS,
  SET_COMPANY,
  SET_PROFILE,
  SET_MAILS_SETTINGS,
  SET_COMPANY_SETTINGS,
  SET_PERMISSIONS_SETTINGS,
  SET_MFA_CODE,
  SET_BASIC_DATA,
  SET_2FA_ENABLED,
  SET_ROLE_LIST,
  RESET_OPEN_TABS,
  SET_LOGGING_OUT,
  SET_LIVE_FEEDS,
  SET_LANDING_TO_LAST_SEEN_EMAIL,
  SYNCHRONIZING_DATA,
  SET_SSO_HISTORY,
  ADD_SSO_HISTORY,
  REMOVE_SSO_HISTORY,
  RESET_SSO_HISTORY,
  SET_COMPANY_META,
  SET_COUNTRIES,
  RESET_COUNTRIES,
  RESET_COMPANY_META,
  SET_SETTING_CHANGE,
} from "../mutation-type";
import storageConstant from "@/common/constants/local-storage.constant";
import { handleMultiTenant } from "../../fetch/api";
import * as Sentry from "@sentry/vue";
import appConstant from '@/common/constants/app.constant';
import LocalStorageConstant from '@/common/constants/local-storage.constant';
import dayjs from 'dayjs';
import store from '@/vuex';

const savedUserSettings = util.storeInLocalStorage.get(storageConstant.SETTING_MAILS) || {};
if (!savedUserSettings.compose_message_in_new_window) {
  savedUserSettings.compose_message_in_new_window = "true";
}

if (savedUserSettings.live_feed) {
  if (typeof savedUserSettings.live_feed === "string") {
    try {
      const feeds = JSON.parse(savedUserSettings.live_feed);
      savedUserSettings.live_feed = feeds;
    } catch (error) {
      savedUserSettings.live_feed = [];
    }
  }
}

let readMailInterval, unreadCountInterval;

const cleanAllInterval = () => {
  clearInterval(readMailInterval);
  clearInterval(unreadCountInterval);
}

const state = {
  token: undefined,
  mfa_code: util.getMfaCode(),
  twoFAEnabled: util.storeInLocalStorage.get(storageConstant.TWO_FA_ENABLED),
  userInfo: util.storeInLocalStorage.get(storageConstant.USER) || { user: {}, company: {} },
  teamMembers: [],
  deactivatedTeamMembers: [],
  mailsSettings: savedUserSettings,
  companySettings: util.storeInLocalStorage.get(storageConstant.SETTING_COMPANY) || {},
  permissionsSettings: util.storeInLocalStorage.get(storageConstant.SETTING_PERMISSIONS),
  loggingOut: false,
  landingToLastSeenEmail: false,
  synchronizingData: false,
  ssoHistory: JSON.parse(localStorage.getItem(storageConstant.SSO_HISTORY)) || [],
  companyMeta: util.storeInLocalStorage.get(storageConstant.COMPANY_META) || [],
  countries: util.storeInLocalStorage.get(storageConstant.COUNTRY_LIST) || [],
  settingChanged: false,
};
const getters = {
  userInfo: (state) => state.userInfo,
  currentUser: (state) => state.userInfo.user,
  teamMembers: (state) => {
    state.teamMembers = state.teamMembers.filter((member) => member.deleted_at == null);
    return state.teamMembers.sort((a, b) => {
      a.sortName = a.name || a.user_name || a.email;
      b.sortName = b.name || b.user_name || b.email;
      a.sortName = a.sortName.toLowerCase().trim();
      b.sortName = b.sortName.toLowerCase().trim();
      return a.sortName < b.sortName ? -1 : 1;
    });
  },
  landingToLastSeenEmail: (state) => state.landingToLastSeenEmail,
  synchronizingData: (state) => state.synchronizingData,
  deactivatedTeamMembers: (state) => {
    state.deactivatedTeamMembers = state.deactivatedTeamMembers.filter((member) => member.deleted_at == null);
    return state.deactivatedTeamMembers.sort((a, b) => {
      a.sortName = a.name || a.user_name || a.email;
      b.sortName = b.name || b.user_name || b.email;
      a.sortName = a.sortName.toLowerCase().trim();
      b.sortName = b.sortName.toLowerCase().trim();
      return a.sortName < b.sortName ? -1 : 1;
    });
  },
  setting_mails: (state) => state.mailsSettings,
  setting_company: (state) => state.companySettings,
  setting_permissions: (state) => state.permissionsSettings,
  twoFAEnabled: (state) => state.twoFAEnabled,
  ssoHistory: (state) => state.ssoHistory,
  countries: (state) => state.countries,
  companyMeta: (state) => state.companyMeta,
  settingChanged: (state) => state.settingChanged,
};
const mutations = {
  [SET_USER]: (state, user) => {
    state.userInfo.user = user;
  },

  [SET_COMPANY]: (state, company) => {
    state.userInfo.company = company;
  },

  [SET_TOKEN]: (state, auth_token) => {
    state.token = auth_token;
  },

  [SET_MFA_CODE]: (state, mfa_code) => {
    state.mfa_code = mfa_code;
    const remember = util.storeInLocalStorage.get(storageConstant.REMEMBER);
    if (remember) {
      Cookies.remove(storageConstant.MFA_CODE);
      util.storeInLocalStorage.set(storageConstant.MFA_CODE, mfa_code);
    } else {
      Cookies.set(storageConstant.MFA_CODE, mfa_code);
      localStorage.removeItem(storageConstant.MFA_CODE);
    }
  },

  [SET_TEAM_MEMBERS]: (state, users) => {
    state.teamMembers = [...users];
  },

  [SET_DEACTIVATED_TEAM_MEMBERS]: (state, users) => {
    state.deactivatedTeamMembers = [...users];
  },

  [SET_PROFILE]: (state, { company, user }) => {
    if (!util.isEmptyObject(user)) {
      state.userInfo.user.avatar = user.avatar;
      state.userInfo.user.name = user.name;
      state.userInfo.user.timezone_id = user.timezone_id;
    } else {
      state.userInfo.user = {};
    }
    state.userInfo.company = company;
    util.storeInLocalStorage.set(storageConstant.USER, state.userInfo);
  },

  [SET_MAILS_SETTINGS]: (state, obj) => {
    if (
      obj?.mail_open_mode &&
      state.mailsSettings?.mail_open_mode &&
      state.mailsSettings?.mail_open_mode != obj?.mail_open_mode
    ) {
      localStorage.setItem(LocalStorageConstant.OPEN_MODE, obj?.mail_open_mode);
    }
    state.mailsSettings = util.isEmptyObject(obj) ? {} : Object.assign(state.mailsSettings, obj);

    let stringFeeds = state.mailsSettings.live_feed;
    if (typeof stringFeeds !== "string") {
      const lf = Array.isArray(state.mailsSettings.live_feed) ? state.mailsSettings.live_feed : [];

      const feeds = lf.map((f) => {
        delete f["utils"];
        return f;
      });
      stringFeeds = JSON.stringify(feeds);
    }

    util.storeInLocalStorage.set(storageConstant.SETTING_MAILS, { ...state.mailsSettings, live_feed: stringFeeds });
  },

  [SET_COMPANY_SETTINGS]: (state, obj) => {
    state.companySettings = obj;
    util.storeInLocalStorage.set(storageConstant.SETTING_COMPANY, state.companySettings);
  },

  [SET_PERMISSIONS_SETTINGS]: (state, obj) => {
    state.permissionsSettings = obj;
    util.storeInLocalStorage.set(storageConstant.SETTING_PERMISSIONS, state.permissionsSettings);
  },
  [SET_2FA_ENABLED]: (state, enabled) => {
    state.twoFAEnabled = enabled;
    util.storeInLocalStorage.set(storageConstant.TWO_FA_ENABLED, enabled);
  },
  [SET_LOGGING_OUT]: (state, val) => {
    state.loggingOut = val;
  },
  [SET_LANDING_TO_LAST_SEEN_EMAIL]: (state, val) => {
    state.landingToLastSeenEmail = val;
  },
  [SET_SETTING_CHANGE]: (state, val) => {
    state.settingChanged = val;
  },
  [SYNCHRONIZING_DATA]: (state, val) => {
    state.synchronizingData = val;
  },
  [SET_SSO_HISTORY]: (state, value) => {
    state.ssoHistory = value;
    localStorage.setItem(LocalStorageConstant.SSO_HISTORY, JSON.stringify(value));
  },
  [ADD_SSO_HISTORY]: (state, domain) => {
    const index = state.ssoHistory.indexOf(domain);
    // reorder sso history
    if (index !== -1) {
      let [item] = state.ssoHistory?.splice(index, 1);
      // Add the item to the beginning of the array
      state.ssoHistory?.unshift(item);
      localStorage.setItem(LocalStorageConstant.SSO_HISTORY, JSON.stringify(state.ssoHistory));
      return;
    }
    state.ssoHistory.unshift(domain);
    if (state.ssoHistory.length > 3) state.ssoHistory.pop();
    localStorage.setItem(LocalStorageConstant.SSO_HISTORY, JSON.stringify(state.ssoHistory));
  },
  [REMOVE_SSO_HISTORY]: (state, domain) => {
    const index = state.ssoHistory.indexOf(domain);
    if (index != -1) {
      state.ssoHistory.splice(index, 1);
      localStorage.setItem(LocalStorageConstant.SSO_HISTORY, JSON.stringify(state.ssoHistory));
    }
  },
  [RESET_SSO_HISTORY]: (state) => {
    state.ssoHistory = [];
    localStorage.setItem(LocalStorageConstant.SSO_HISTORY, JSON.stringify(state.ssoHistory));
  },
  [SET_COUNTRIES]: (state, countries) => {
    state.countries = countries;
    util.storeInLocalStorage.set(storageConstant.COUNTRY_LIST, state.countries);
  },
  [RESET_COUNTRIES]: (state) => {
    state.countries = [];
    localStorage.setItem(LocalStorageConstant.COUNTRY_LIST, JSON.stringify(state.countries));
  },
  [SET_COMPANY_META]: (state, company_meta) => {
    state.companyMeta = company_meta;
    util.storeInLocalStorage.set(storageConstant.COMPANY_META, state.companyMeta);
  },
  [RESET_COMPANY_META]: (state) => {
    state.companyMeta = [];
    localStorage.setItem(LocalStorageConstant.COMPANY_META, JSON.stringify(state.companyMeta));
  },
};
const actions = {
  Login({ dispatch }, params) {
    return new Promise((resolve, reject) => {
      // const fetchApi = params.captcha ? api.login(params) : api.loginWithoutCapcha(params);
      const fetchApi = api.login_session_cookie(params);
      fetchApi
        .then(async (res) => {
          if (!res || !res.auth_token) {
            reject(res);
            return;
          }
          dispatch("handleLoginSuccess", res);
          resolve(res);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },

  loginWith2FA({ dispatch }, code) {
    return dispatch("verify2FACode", code).then((data) => {
      dispatch("loadDataAfterLogin");
    });
  },

  verify2FACode({ commit, state }, authenCode) {
    return api.verify_2fa_code({ code: authenCode }, state.token).then((res) => {
      if (res && res.mfa_code) {
        commit(SET_MFA_CODE, res.mfa_code);
        commit(SET_2FA_ENABLED, true);
      }
    });
  },

  disable2FA({ commit, dispatch }, authenCode) {
    return dispatch("save2FASetting", { enable: false, authenCode }).then((_) => {
      commit(SET_MFA_CODE, "");
      commit(SET_2FA_ENABLED, false);
    });
  },

  async save2FASetting({ commit }, { enable, authenCode }) {
    const param = {
      keys: ["google_authenticator"],
      values: [`${enable}`],
    };
    authenCode && (param.code = authenCode);
    await api.set_user_settings(param);
    commit(SET_MAILS_SETTINGS, { ...state.mailsSettings, google_authenticator: `${enable}` });
  },

  loginWithSSO({ dispatch }, { token, sso_tenant_id }) {
    return new Promise((resolve, reject) => {
      api
        .login_with_sso(token, sso_tenant_id)
        .then(async (res) => {
          if (!res || !res.auth_token) {
            reject(res);
            return;
          }
          dispatch("handleLoginSuccess", res);
          resolve(res);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },

  loadQRCode({ state }) {
    return api.load_qr_image(state.userInfo.user.id, state.token);
  },

  async handleAuth0Done({commit, dispatch}, {token}) {
    await getBaseUrl(token);

    const res = await api.load_startup_data(token);
    if(!res) return

    commit(SET_USER, res.user);
    commit(SET_COMPANY, res.company);
    commit(SET_MAILS_SETTINGS, res.user_settings);
    commit(SET_PERMISSIONS_SETTINGS, res.permissions);
    commit(SET_PROFILE, { company: res.company, user: res.user });
    commit(SET_COMPANY_SETTINGS, res.company_settings);

    window.timeGap = +new Date(res.auth_at) - Date.now();
    localStorage.setItem("timeGap", JSON.stringify(window.timeGap));

    process.env.NODE_ENV === "production" 
      && Sentry.setUser({ id: res.user?.id });

    util.changeTheme(res.user_settings.theme_colour || appConstant.themeNames.ORIGINAL);
    handleMultiTenant();
    dispatch("handleInterval");
    dispatch("loadDataAfterLogin");
  },

  handleInterval({dispatch}) {
    readMailInterval = setInterval(() => {
      store.state.online && dispatch("readMultipleEmails", { retry: true });
    }, 5*1000);
    unreadCountInterval = setInterval(() => {
      store.state.online && dispatch("loadLines");
    }, 1000*60*60*6); // 6 hours
  },

  handleLoginSuccess({ commit, dispatch }, res) {
    commit(SET_2FA_ENABLED, res.enable_2fa);
    commit(SET_TOKEN, res.auth_token);
    commit(SET_USER, res.user);
    commit(SET_COMPANY, res.company);
    commit(SET_MAILS_SETTINGS, res.user_settings);
    commit(SET_PERMISSIONS_SETTINGS, res.permissions);
    commit(SET_PROFILE, { company: res.company, user: res.user });
    commit(SET_COMPANY_SETTINGS, res.company_settings);

    window.timeGap = +new Date(res.auth_at) - Date.now();
    localStorage.setItem("timeGap", JSON.stringify(window.timeGap));

    process.env.NODE_ENV === "production" 
      && Sentry.setUser({ id: res.user?.id });

    util.changeTheme(res.user_settings.theme_colour);
    handleMultiTenant();
    const no2FA =
      !res.enable_2fa && (!res.user_settings.google_authenticator || res.user_settings.google_authenticator == "false");
    no2FA && dispatch("loadDataAfterLogin");
  },

  loadDataAfterLogin({dispatch, commit}) {
    dispatch("loadBasicData");
    dispatch("getRoles");
    dispatch("getContactCountries");
    dispatch("getCompanyMeta");
    dispatch("GetTeamMembers", { active: true });
    dispatch("getUserSettings");
    dispatch("getCompanySettings");
    !location.href.includes("calendar")
      && state.companySettings.calendar_module == '1' 
      && dispatch("getCalendarResources").then(_ => {
        dispatch("getCalendarEvents", {
          start: dayjs().subtract(1, 'day').toISOString(),
          end: dayjs().add(1, 'day').toISOString()
        })
      });
    state.companySettings.limit_hashtag == '1' && dispatch("getTags");
    dispatch("getBookmark").then(bookmarks => {
      const hasAutoBookmark = bookmarks && bookmarks.findIndex(b => b.bookmark_type === appConstant.bookmarkType.INVISIBLE) > -1
      hasAutoBookmark && commit(SET_LANDING_TO_LAST_SEEN_EMAIL, true)
      dispatch("getOpenTabs", !hasAutoBookmark)
    })
    dispatch("getHelpCenterVersion")
    dispatch("syncAutoBookmark")
  },

  async logout({commit, state, dispatch}) {
    cleanAllInterval();
    commit("REMOVE_ALL_INAPP_WINDOW");
    await dispatch('syncDataBeforeLogout');
    dispatch('resetUserData');
  },
  async syncDataBeforeLogout({ dispatch, commit }) {
    commit(SYNCHRONIZING_DATA, true);
    try {
      await dispatch("readMultipleEmails", { retry: false });
      await dispatch("saveLocalAutoBookmark", { retry: false });
    } catch (e) {
      commit(SYNCHRONIZING_DATA, false);
    }
    setTimeout((_) => commit(SYNCHRONIZING_DATA, false), 500);
  },
  resetUserData({ commit }) {
    commit(SET_TOKEN, "");
    commit(SET_MFA_CODE, "");
    commit(SET_PROFILE, { user: {}, company: {} });
    commit(SET_COMPANY, {});
    commit(SET_MAILS_SETTINGS, {});
    commit(SET_COMPANY_SETTINGS, {});
    commit(SET_PERMISSIONS_SETTINGS, {});
    commit(SET_BASIC_DATA, {});
    commit(SET_TEAM_MEMBERS, []);
    commit(SET_DEACTIVATED_TEAM_MEMBERS, []);
    commit(SET_ROLE_LIST, []);
    commit(SET_USER, {});
    commit(RESET_OPEN_TABS);
    commit(RESET_COMPANY_META);
    commit(RESET_COUNTRIES);

    Cookies.remove(storageConstant.AUTHORIZATION);
    localStorage.removeItem(storageConstant.TOKEN);
    util.storeInLocalStorage.set(storageConstant.LINES, []);
    localStorage.removeItem(storageConstant.OPEN_MODE)
    localStorage.removeItem(storageConstant.COPIED_ATTACHMENTS)
    localStorage.removeItem(storageConstant.AUTO_BOOKMARK)
  },
  getUserSettings({ commit }) {
    api
      .get_user_settings()
      .then(({ settings }) => {
        typeof settings.live_feed === "string" && (settings.live_feed = JSON.parse(settings.live_feed));

        commit(SET_MAILS_SETTINGS, settings);
        commit(SET_LIVE_FEEDS, settings.live_feed);
      })
      .catch((error) => console.log(error));
  },
  getContactCountries({ commit }) {
    api
      .get_contact_countries()
      .then(({ countries }) => {
        commit(SET_COUNTRIES, countries);
      })
      .catch((error) => console.log(error));
  },
  getCompanyMeta({ commit }) {
    api
      .get_company_meta()
      .then(({ companies }) => {
        commit(SET_COMPANY_META, companies);
      })
      .catch((error) => console.log(error));
  },
  getCompanySettings({ commit }) {
    api
      .get_company_settings()
      .then(({ settings }) => commit(SET_COMPANY_SETTINGS, settings))
      .catch((error) => console.log(error));
  },
  updateCompanySetting({ commit }, params) {
    return api
      .set_company_settings(params)
      .then((res) => {
        return new Promise((resolve, reject) => {
          if (!res || !res.settings) {
            reject(res);
            return;
          }
          commit("SET_COMPANY_SETTINGS", res.settings, 0);
          resolve(res);
        });
      })
      .catch((error) => {
        return new Promise((_, reject) => {
          reject(error);
        });
      });
  },

  GetTeamMembers({ commit }, params) {
    return api
      .get_team_members({ all_users: true })
      .then((res) => {
        return new Promise((resolve, reject) => {
          if (res.users) {
            const activatedUsers = [];
            const deactivatedUsers = [];
            res.users.forEach((user) => {
              if (user.active) {
                activatedUsers.push(user);
              } else {
                deactivatedUsers.push(user);
              }
            });

            commit(SET_TEAM_MEMBERS, activatedUsers);
            commit(SET_DEACTIVATED_TEAM_MEMBERS, deactivatedUsers);
            resolve(res);
          } else {
            reject(res);
          }
        });
      })
      .catch(function (error) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      });
  },

  async setTheme({ commit }, themeName) {
    const param = {
      keys: ["theme_colour"],
      values: [themeName],
    };
    await api.set_user_settings(param);
    commit(SET_MAILS_SETTINGS, { ...state.mailsSettings, theme_colour: themeName });
    util.changeTheme(themeName);
  },

  async updatePreviewMailSetting({ commit }, isPreview) {
    const param = {
      keys: ["preview_mail_body"],
      values: [`${isPreview}`],
    };
    await api.set_user_settings(param);
    commit(SET_MAILS_SETTINGS, { ...state.mailsSettings, preview_mail_body: `${isPreview}` });
  },

  async setOpenAICommand({ commit }, command) {
    const param = {
      keys: ["openai_command"],
      values: [command],
    };
    await api.set_user_settings(param);
    commit(SET_MAILS_SETTINGS, { ...state.mailsSettings, openai_command: command });
  },

  async updateHeaderFooterContent({ commit }, param) {
    await api.set_user_settings(param);
    commit(SET_MAILS_SETTINGS, { ...state.mailsSettings, [param.keys[0]]: param.values[0] });
  },

  async updateMailboxColors({ commit }, line_colors) {
    await api.set_user_settings({
      keys: ["line_colors"],
      values: [JSON.stringify(line_colors)],
    });
    commit(SET_MAILS_SETTINGS, { ...state.mailsSettings, line_colors: `${JSON.stringify(line_colors)}` });
  },

  async updateUserSettings({ commit }, params) {
    const res = await api.set_user_settings(params);
    res && commit(SET_MAILS_SETTINGS, res.settings);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
