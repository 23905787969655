//------------------------------------------user
export const SET_TOKEN = "SET_TOKEN";
export const SET_MFA_CODE = "SET_MFA_CODE";
export const SET_USER = "SET_USER";
export const SET_PROFILE = "SET_PROFILE";
export const SET_COMPANY = "SET_COMPANY";
export const SET_TEAM_MEMBERS = "SET_TEAM_MEMBERS";
export const SET_DEACTIVATED_TEAM_MEMBERS = "SET_DEACTIVATED_TEAM_MEMBERS";
export const SET_2FA_ENABLED = "SET_2FA_ENABLED";
export const SET_LOGGING_OUT = "SET_LOGGING_OUT";
export const SET_LANDING_TO_LAST_SEEN_EMAIL = "SET_LANDING_TO_LAST_SEEN_EMAIL";
export const SYNCHRONIZING_DATA = "SYNCHRONIZING_DATA";
export const SET_SETTING_CHANGE = "SET_SETTING_CHANGE";
//------------------------------------------mails
export const ADD_LINES = "ADD_LINES";
export const ADD_SENDABLE_LINES = "ADD_SENDABLE_LINES";
export const ADD_LINES_ALL = "ADD_LINES_ALL";
export const UPDATE_LINE_STATUS = "UPDATE_LINE_STATUS";
export const UPDATE_LINE_COLOR = "UPDATE_LINE_COLOR";
//mail history
export const ADD_THREAD_EMAILS = "ADD_THREAD_EMAILS";
export const ADD_BODY_TO_HISTORY_EMAIL = "ADD_BODY_TO_HISTORY_EMAIL";
export const START_LOADING_THREAD = "START_LOADING_THREAD";
export const FINISH_LOADING_THREAD = "FINISH_LOADING_THREAD";
//next folder
export const SET_NESTED_FOLDER = "SET_NESTED_FOLDER";
export const SET_DATA_RIGHT_CLICK = "SET_DATA_RIGHT_CLICK";
export const SET_FOLDER_TO_COPY = "SET_FOLDER_TO_COPY";
export const REMOVE_RIGHT_CLICK_FOLDER = "REMOVE_RIGHT_CLICK_FOLDER";
// mail unread count
export const READ_EMAIL = "READ_EMAIL"; //read mail
export const ADD_EMAIL_TO_READ = "ADD_EMAIL_TO_READ";
export const RESET_EMAIL_TO_READ = "RESET_EMAIL_TO_READ";
export const SET_LINECOUNT = "SET_LINECOUNT"; //增加mailsCount
export const SET_DRAGED_MAIL = "SET_DRAGED_MAIL";

//mail list
export const UPDATE_MAIL_LIST = "UPDATE_MAIL_LIST";
export const UPDATE_MAIL_LIST_ITEMS = "UPDATE_MAIL_LIST_ITEMS";
export const DELETE_MAIL_LIST_ITEMS = "DELETE_MAIL_LIST_ITEMS";
export const UPDATE_MAIL_PATH = "UPDATE_MAIL_PATH";
export const SET_EMAIL_OUT_OF_SYNC = "SET_EMAIL_OUT_OF_SYNC";
//advance search
export const SET_MAIL_ADVANCE = "SET_MAIL_ADVANCE";
//rightMenu
export const SET_FOLDERID = "SET_FOLDERID";
//folder
export const SET_ROOT_FOLDER = "SET_ROOT_FOLDER";
export const ADD_FOLDER_TO_ROOT = "ADD_FOLDER_TO_ROOT";
export const SET_CHILD_FOLDERS = "SET_CHILD_FOLDERS";
export const PREPEND_CHILD_FOLDERS = "PREPEND_CHILD_FOLDERS";
export const LOADING_CHILD_FOLDERS = "LOADING_CHILD_FOLDERS";
export const SET_SELECTED_FOLDER = "SET_SELECTED_FOLDER";
export const UPDATE_FOLDER_INFO = "UPDATE_FOLDER_INFO";
export const UPDATE_FOLDER_CHILDREN = "UPDATE_FOLDER_CHILDREN";
export const ADD_OPEN_FOLDER = "ADD_OPEN_FOLDER";
export const REMOVE_OPEN_FOLDER = "REMOVE_OPEN_FOLDER";
export const SET_DRAGGING_FOLDER = "SET_DRAGGING_FOLDER";

export const SET_OPEN_TABS = "SET_OPEN_TABS";
export const ADD_OPEN_TAB = "ADD_OPEN_TAB";
export const REMOVE_OPEN_TAB = "REMOVE_OPEN_TAB";
export const RESET_OPEN_TABS = "RESET_OPEN_TABS";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_EXPANDED_PREVIEW_MODE = "SET_EXPANDED_PREVIEW_MODE";
export const UPDATE_INAPP_ATTACHMENT_PREVIEW = "UPDATE_INAPP_ATTACHMENT_PREVIEW";
export const ADD_TAB_ATTACHMENT = "ADD_TAB_ATTACHMENT";
export const RESET_TAB_ATTACHMENT = "RESET_TAB_ATTACHMENT";
export const UPDATE_CURRENT_TAB_ATTACHMENT = "UPDATE_CURRENT_TAB_ATTACHMENT";
export const SET_LIVE_FEEDS = "SET_LIVE_FEEDS";
//folder 未读
export const SET_STAR_UNREAD = "SET_STAR_UNREAD";
export const SET_SHARE_UNREAD = "SET_SHARE_UNREAD";
export const SET_PERSONAL_UNREAD = "SET_PERSONAL_UNREAD";
//------------------------------------------ships
//advance search
export const SET_SHIP_ADVANCE = "SET_SHIP_ADVANCE";

// -------------------------------------contacts
export const SET_DRAGGED_CONTACT = "SET_DRAGGED_CONTACT";
export const SET_SELECTED_NAV = "SET_SELECTED_NAV";
export const SET_CONTACT_NAV_GROUPS = "SET_CONTACT_NAV_GROUPS";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_COMPANY_META = "SET_COMPANY_META";
export const RESET_COUNTRIES = "RESET_COUNTRIES";
export const RESET_COMPANY_META = "RESET_COMPANY_META";

//-----------------------------------------setting
export const SET_MAILS_SETTINGS = "SET_MAILS_SETTINGS";
export const SET_COMPANY_SETTINGS = "SET_COMPANY_SETTINGS";
export const SET_PERMISSIONS_SETTINGS = "SET_PERMISSIONS_SETTINGS";
export const SET_CONFIGURED_TAGS = "SET_CONFIGURED_TAGS";
//------------------------------------------basic data
export const SET_BASIC_DATA = "SET_BASIC_DATA";
export const TOGGLE_PIN_MENU = "TOGGLE_PIN_MENU";
export const SET_HELP_CENTER_VERSION = "SET_HELP_CENTER_VERSION";

export const ADD_INAPP_COMPOSE_WINDOW = "ADD_INAPP_COMPOSE_WINDOW";
export const REMOVE_INAPP_WINDOW = "REMOVE_INAPP_WINDOW";
export const REMOVE_ALL_INAPP_WINDOW = "REMOVE_ALL_INAPP_WINDOW";
export const TOGGLE_MINIMIZE = "TOGGLE_MINIMIZE";
export const INAPP_WINDOW_MINIMIZE_ALL = "INAPP_WINDOW_MINIMIZE_ALL";
export const UPDATE_INAPP_COMPOSE_DATA = "UPDATE_INAPP_COMPOSE_DATA";

export const SET_MAIL_DETAIL = "SET_MAIL_DETAIL";
export const SET_IS_PREVIEWING_ATTACHMENT = "SET_IS_PREVIEWING_ATTACHMENT";
export const TOGGLE_EMBEDED = "TOGGLE_EMBEDED";
export const HAS_EMBEDED_FILE = "HAS_EMBEDED_FILE";
export const UPDATE_CONTACT_DATA = "UPDATE_CONTACT_DATA";
export const LOADING_ROLE = "LOADING_ROLE";
export const SET_ROLE_LIST = "SET_ROLE_LIST";
export const SET_ROLE_DETAIL = "SET_ROLE_DETAIL";

export const SET_USER_DETAIL = "SET_USER_DETAIL";
export const SET_PENDING_INVITES = "SET_PENDING_INVITES";
export const SET_INVITE_SEARCH_TEXT = "SET_INVITE_SEARCH_TEXT";
export const SET_MAXIMUM_LINCENSES = "SET_MAXIMUM_LINCENSES";
export const SET_ACTIVE_USERS_COUNT = "SET_ACTIVE_USERS_COUNT";

export const SET_ROLE_DISABLE_MENTION = "SET_ROLE_DISABLE_MENTION";
export const SET_CONTACT_PRE_UPDATE = "SET_CONTACT_PRE_UPDATE";
export const RESET_CONTACT_PRE_UPDATE = "RESET_CONTACT_PRE_UPDATE";
export const SET_CONTACT_GROUPS = "SET_CONTACT_GROUPS";

export const SET_TEMPLATES = "SET_TEMPLATES";
export const SET_CURRENT_TEMPLATE = "SET_CURRENT_TEMPLATE";
export const RESET_CURRENT_TEMPLATE = "RESET_CURRENT_TEMPLATE";

export const SET_ATTACHMENTS = "SET_ATTACHMENTS";
export const ADD_ATTACHMENT = "ADD_ATTACHMENT";
export const ADD_MULTI_ATTACHMENT = "ADD_MULTI_ATTACHMENT";
export const REMOVE_ATTACHMENT = "REMOVE_ATTACHMENT";

export const UPDATE_ONLINE_STATUS = "UPDATE_ONLINE_STATUS";
export const ADD_EXPANDING_NAV_ITEM = "ADD_EXPANDING_NAV_ITEM";
export const REMOVE_EXPANDING_NAV_ITEM = "REMOVE_EXPANDING_NAV_ITEM";

export const LOADING_CALENDAR = "LOADING_CALENDAR";
export const LOADING_CALENDAR_EVENTS = "LOADING_CALENDAR_EVENTS";
export const LOADING_FORM_SUBMIT = "LOADING_FORM_SUBMIT";
export const SET_CALENDAR_EVENTS = "SET_CALENDAR_EVENTS";
export const UPDATE_CALENDAR_EVENT = "UPDATE_CALENDAR_EVENT";
export const DELETE_CALENDAR_EVENT = "DELETE_CALENDAR_EVENT";
export const SET_SELECTED_CALENDAR_RESOURCES = "SET_SELECTED_CALENDAR_RESOURCES";
export const SET_CALENDAR_RESOURCES = "SET_CALENDAR_RESOURCES";

// Tasks
export const SET_TASKS = "SET_TASKS";
export const ADD_TASK = "ADD_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const SET_TASKLIST_STATUS = "SET_TASKLIST_STATUS";
export const SET_TASK_LOADING = "SET_TASK_LOADING";

// SSO history
export const SET_SSO_HISTORY = "SET_SSO_HISTORY";
export const ADD_SSO_HISTORY = "ADD_SSO_HISTORY";
export const REMOVE_SSO_HISTORY = "REMOVE_SSO_HISTORY";
export const RESET_SSO_HISTORY = "RESET_SSO_HISTORY";
