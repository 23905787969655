<template>
  <div v-if="showTab" class="tabs-wrapper mailDetailTab tab-align-center" :class="{ vertical: this.previewVertical }">
    <email-tab-panel @tab-selected="(tab) => (currentTab = tab)">
      <template v-slot:comments>
        <ch-chat
          :uniqueId="mailDetail.email_unique_id"
          :commentable_id="mailId"
          :comments="comments"
          :comment_type="'Email'"
          :placeholder="'Mention @yourteammate or #tag this email'"
          ref="chChat"
          :hideReplyEditor="isSpamMail"
          :readOnly="isSpamMail"
        >
        </ch-chat>
      </template>
      <template v-slot:details>
        <div class="details-contain">
          <div class="loading" v-show="detailLoading">
            <Spin size="large" fix></Spin>
          </div>
          <div class="details-item">
            <div class="details-header">
              <i class="iconfont-actions"></i>
              <span>Actions</span>
            </div>
            <div v-if="actions && actions.length > 0" class="details-content">
              <ul class="actions">
                <li v-for="(action, index) in actions" class="action-item" :key="index">
                  <span class="action-datetime">{{ action.datetime }}: </span>
                  <b v-if="action.username">{{ action.username }} </b>
                  <span v-html="action.actionText"></span>
                  <b v-if="action.targetUsername"> {{ action.targetUsername }}</b>
                </li>
              </ul>
            </div>
            <div v-else class="noData">
              <p>No data found</p>
            </div>
          </div>
          <!--Assignment-->
          <div class="details-item" v-if="p_enabled_assignment">
            <div class="details-header">
              <i class="iconfont-user"></i>
              <span>Assigned to</span>
            </div>
            <div v-if="mailDetail.assignment_ids && mailDetail.assignment_ids.length > 0" class="details-content">
              <assignment
                class="assignment"
                :readOnly="true"
                :subjects="[mailDetail]"
                :value="mailDetail.assignment_ids"
                :show-desc-text="false"
              />
            </div>
            <div v-else class="noData">
              <p>No data found</p>
            </div>
          </div>

          <!--Mentions-->
          <div class="details-item">
            <div class="details-header">
              <i class="iconfont-mentions"></i>
              <span>Mentioned to</span>
            </div>
            <Tooltip
              v-if="mentions && mentions.length > 0"
              class="details-content mentions"
              placement="bottom-start"
              :content="mentions.map((a) => a.name).join(', ')"
            >
              <div v-for="(mention, index) in mentions" :key="index" class="mention-item">
                <avatar
                  class="avatar"
                  :avatarURL="mention.avatar"
                  :fullname="mention.name"
                  :width="33"
                  :show-tooltip="false"
                />
                <Icon
                  v-if="!isSpamMail"
                  type="md-close"
                  class="ic-remove-mention"
                  @click="showRemoveMentionConfirm(mention)"
                />
              </div>
            </Tooltip>
            <div v-else class="noData">
              <p>No data found</p>
            </div>
          </div>

          <!--Tagged-->
          <div class="details-item">
            <div class="details-header">
              <i class="iconfont-tag"></i>
              <span>Tagged</span>
            </div>
            <div v-if="tags.length > 0" class="details-content">
              <div v-for="(tag, index) in tags" class="tagItem" :key="index">
                {{ tag }}
                <i v-if="canDeteleTag(tag)" class="iconfont ch-icon-cross_1" @click="deleteTag(tag, index)" />
              </div>
            </div>
            <div v-else class="noData">
              <p>No data found</p>
            </div>
          </div>
          <!-- recieved by  -->
          <div class="details-item" v-if="mailDetail.status == 'received'">
            <div class="details-header mail-header">
              <i class="iconfont-recieved-by" />
              <span>Received by</span>
            </div>
            <div class="details-content">
              <template v-for="line in lines">
                <div class="mail-boxes" v-if="checkAvailalable(line.id)" :key="'line' + line.id">
                  <span class="dot" :style="{ 'background-color': line.colour }"></span
                  >{{ line.username || line.display_name }}
                </div>
              </template>
            </div>
          </div>
          <!--Filed under-->
          <div class="details-item">
            <div class="details-header">
              <i class="iconfont-folder"></i>
              <span>Filed under</span>
            </div>
            <ul v-if="email_folders.length > 0" class="details-content folders">
              <li class="folder-info" v-for="(item, index) in email_folders" :key="index">
                <h1 @click="clickFolder(item)">{{ item.folder }}</h1>
                <Poptip
                  :disabled="!item.rule_id || item.rule_id == 0"
                  placement="top-start"
                  width="200"
                  @on-popper-show="getRule(item)"
                >
                  <p class="filed-by">
                    {{ item.user_id > 0 ? `Filed` : `Auto-filed` }}
                    {{ item.user_id > 0 || item.rule_id > 0 || item.match_type == "every" ? "by" : "" }}
                    <span :class="{ rules: item.rule_id > 0 }">
                      {{
                        item.user_id > 0
                          ? item.user_name
                          : item.rule_id == 0 && item.match_type == "every"
                          ? " folder rules"
                          : item.rule_id > 0
                          ? ` RULE #${item.rule_id}`
                          : item.user_name
                      }}
                    </span>
                  </p>
                  <div slot="content">
                    <span v-if="loadingRule">Loading...</span>
                    <div v-else-if="!loadingRule && selectedRule">
                      {{ selectedRule.name }} {{ selectedRule.strategy && selectedRule.strategy.toLowerCase() }}
                      {{ selectedRule.content }}
                    </div>
                  </div>
                </Poptip>
                <p class="filed-date">{{ item.created_at | format_date_time }}</p>
              </li>
            </ul>
            <div v-else class="noData">
              <p>No data found</p>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:positions>
        <div class="positions-wrapper">
          <div class="loading" v-show="posLoading">
            <Spin size="large" fix></Spin>
          </div>
          <div class="positions-contain">
            <div class="position-title">{{ temp_positions.length }} ship position detected</div>
            <div class="position-list" v-if="temp_positions.length > 0">
              <div class="list-item" v-for="(item, index) in temp_positions" :key="index">
                <div class="indexNumber">
                  <span v-show="index < 9">0{{ index + 1 }}</span>
                  <span v-show="index >= 9">{{ index + 1 }}</span>
                </div>
                <div class="positionInfo">
                  {{ item.ship_name }}, {{ item.port_name }},
                  <span>{{ item.open_date | formatDays }}</span>
                  <span v-show="item.days > 0"> + {{ item.days }} days</span>
                  <br />
                  <span v-show="item.note !== ''">{{ item.note }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isSpamMail" class="addPosition" @click="updateShipPosition">
            <i class="iconfont iconfont-ship-add"></i>
            <span>Add/Edit positions</span>
          </div>
          <div class="positions-contain" v-show="ship_positions.length > 0">
            <div class="position-title">{{ ship_positions.length }} ship open positions added</div>
            <div class="position-list">
              <div class="list-item" v-for="(item, index) in ship_positions" :key="index">
                <div class="indexNumber">
                  <span v-show="index < 9">0{{ index + 1 }}</span>
                  <span v-show="index >= 9">{{ index + 1 }}</span>
                </div>
                <div class="positionInfo">
                  {{ item.ship_name }}, {{ item.port_name }},
                  <span>{{ item.open_date | formatDays }}</span>
                  <span v-show="item.days > 0"> + {{ item.days }} days</span>
                  <br />
                  <span v-show="item.note !== ''">{{ item.note }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:threads>
        <mail-threads :originalMail="mailDetail" />
      </template>
    </email-tab-panel>
    <Modal
      v-if="selectedMention"
      v-model="mentionRemoveConfirm"
      @on-ok="removeMention"
      @on-cancel="mentionRemoveConfirm = false"
    >
      Are you sure to remove mention @{{ selectedMention.name }}?
    </Modal>
  </div>
</template>
<script>
import EmailTabPanel from "@/components/EmailTabPanel.vue";
import chat from "@/pages/components/comments/Cchat.vue";
import util from "@/utils";
import CommentHelper from "@/helpers/comment.helper";
import { mapActions, mapGetters } from "vuex";
import { ruleEngineMixin } from "@/mixins/rule-engine.mixin";
import { openNewTabMixin } from "@/mixins";
import appConstant from "@/common/constants/app.constant";
import api from "@/fetch/api";
import filter from "@/utils/filter.js";
import assignment from "@/pages/components/assignment";
import MailThreads from "@/components/MailThreads.vue";
import avatar from "@/pages/components/avatar";
import { normalRules, definedSearchRules } from "@/common/constants/rules.constant";

export default {
  props: {
    mailId: null,
    previewEmailId: {
      type: [Number, String],
    },
    mailDetail: {
      type: Object,
    },
    showTab: {
      type: Boolean,
    },
    previewVertical: {
      type: Boolean,
    },
  },
  data() {
    return {
      currentTab: "comments",
      comments: [],
      posLoading: false,
      ship_positions: [],
      temp_positions: [],
      actions: [],
      tags: [],
      email_folders: [],
      mentions: [],
      mentionRemoveConfirm: false,
      selectedMention: undefined,
      loadingRule: false,
      selectedRule: undefined,
      socketChannel: undefined,
      detailLoading: false,
    };
  },
  computed: {
    ...mapGetters(["setting_company", "lines_all", "teamMembers"]),
    isSpamMail() {
      return this.mailDetail.box == "SPAM";
    },
    p_enabled_assignment() {
      return this.setting_company.assignment_module == "1";
    },
    lines() {
      let lines = this.lines_all.lines;
      const fullLines = this.lines_all.full_lines;
      if (fullLines && fullLines.length > 0) {
        const others = fullLines.filter((fl) => lines.findIndex((l) => l.id == fl.id) == -1);
        lines = lines.concat(others);
      }
      return lines;
    },
  },
  mixins: [ruleEngineMixin, openNewTabMixin],
  filters: {
    formatData(GMT) {
      let timeString = "";
      timeString = util.relativeDay(GMT) + ", " + util.time_12(GMT);
      return timeString;
    },
    formatDays(GMT) {
      let time = {};
      let timeString = "";
      if (GMT) {
        time = filter.formatTime(GMT);
        timeString = time.date + " " + time.monthabbr + " " + time.year;
      }
      return timeString;
    },
  },
  mounted() {
    this.subscribeWSChannel();
  },
  beforeDestroy() {
    this.socketChannel.unsubscribe();
  },
  methods: {
    ...mapActions(["loadThreadEmails", "loadLines"]),
    async tabChange() {
      switch (this.currentTab) {
        case "comments":
          return this.getComments();
        case "positions":
          return this.getPositions();
        case "details":
          return this.getEmailDetailActions();
        case "threads":
          await this.loadThreadEmails({ target_id: this.previewEmailId, history: true });
          break;
        default:
          break;
      }
    },
    getComments() {
      if (!this.mailId) return;

      util.getDataFromSw("comments", (data) => {
        this.comments = data.comments;
      });

      api
        .get_comments({
          commentable_id: this.mailId,
          commentable_type: "Email",
        })
        .then((res) => {
          this.comments = res.comments;
        });
    },
    getPositions(val) {
      if (!this.mailId) return;

      this.posLoading = true;
      util.getDataFromSw("ship-positions", (data) => {
        data.ship_positions && data.ship_positions.length > 0 && this.updateUIShipPosition(data);
      });
      api
        .get_position_by_detail(this.mailId)
        .then((res) => {
          this.updateUIShipPosition(res);
        })
        .catch((error) => {
          this.posLoading = false;
          console.log(error);
        });
    },
    updateUIShipPosition(res) {
      this.posLoading = false;
      this.ship_positions = res.ship_positions;
      this.temp_positions = res.temp_positions.slice(0);
    },
    async getEmailDetailActions() {
      if (this.lines_all.lines.length <= 0) this.loadLines();

      const res = await api.get_email_details_actions({
        email_id: this.mailId,
      });
      this.actions = this.buildActions(res.json_actions);
      this.tags = res.tags;
      this.email_folders = res.email_folders.map((item) => {
        if (!item.avatar) {
          item.avatar = util.userAvatar(item.user_id);
        }
        return item;
      });
      let mentions = CommentHelper.parseMentions(res.notifications, this.teamMembers);
      this.mentions = util.distinctArray(mentions, "to_id");
    },
    async deleteTag(name, index) {
      try {
        if (!this.canDeteleTag(name)) return;

        this.detailLoading = true;
        const params = {
          tagable_type: "Email",
          tagable_id: this.mailId,
          names: [name],
        };
        await api.delete_tag(params);
        this.tags.splice(index, 1);
      } catch (e) {
        console.log(e);
      } finally {
        this.detailLoading = false;
      }
    },
    checkAvailalable(id) {
      return this.mailDetail.line_ids.indexOf(id) >= 0;
    },
    async getRule({ rule_id, folder_id, folder_type }) {
      this.loadingRule = true;
      const rule = await api.get_rule(rule_id, folder_id);
      this.loadingRule = false;
      if (!rule) return;
      const ruleTemplates = folder_type == appConstant.folderType.NORMAL ? normalRules : definedSearchRules;
      const convertedRules = this.parseRulesInfo([rule], ruleTemplates);
      this.selectedRule = convertedRules && convertedRules.length > 0 && convertedRules[0];
    },
    updateShipPosition() {
      this.$emit("updateShipPosition");
    },
    resetExtraData() {
      this.comments = [];
      this.ship_positions = [];
      this.temp_positions = [];
      this.actions = [];
      this.tags = [];
      this.email_folders = [];
    },
    clickFolder(i) {
      const query = {
        folder_id: i.folder_id,
        star: (typeof i.starred === "boolean").toString(),
        type: i.folder_type,
        folder_name: i.folder,
      };
      let url = `/mails?folder_id=${query.folder_id}&star=${query.star}&type=${
        query.type
      }&folder_name=${encodeURIComponent(query.folder_name)}`;
      if (window.opener) {
        url = "/#" + url;
        window.opener.window.location.href = url;
        return;
      }
      this.onOpenInTab({
        name: `fid_${i.folder_id}`,
        label: i.folder,
        icon: "iconfont-folder",
        closable: true,
        name_type: appConstant.tabTypes.FOLDER,
        data: JSON.stringify({ id: i.folder_id, folder_type: i.folder_type, name: i.folder }),
        show: true,
        active: true,
      });
    },
    showRemoveMentionConfirm(mention) {
      this.mentionRemoveConfirm = true;
      this.selectedMention = mention;
    },
    async removeMention() {
      await api.removeMentions([this.selectedMention.to_id], this.mailDetail.id);
      this.mentions = this.mentions.filter((m) => m.id != this.selectedMention.id);
      this.$Message.success("Mention removed");
    },
    subscribeWSChannel() {
      this.socketChannel = this.$ActionCable.subscriptions.create(
        { channel: "NotificationsChannel" },
        {
          connected: () => {
            console.log("Mail detail tab WS connected");
          },
          received: (data) => {
            switch (data.type) {
              case "Tag":
                if (!data.message) return;
                data.message.forEach((message) => {
                  if (message.tagable_type === "Email" && message.tagable_id === this.mailDetail.id) {
                    const deleted = !!message.deleted_at;
                    this.updateTags(message.name, message.tagable_id, deleted);
                  }
                });
                break;
              case "Comment":
                if (!data.message) return;
                let number = 0;
                data.message.length > 0 &&
                  data.message.forEach((item) => {
                    if (
                      item.commentable_id === this.mailDetail.id &&
                      item.commentable_type === "Email" &&
                      this.comments.findIndex((c) => c.id == item.id) == -1
                    ) {
                      number += 1;
                      item.notifications = CommentHelper.extractMentions(item);
                      this.comments.push(item);
                    }
                  });
                if (number > 0) {
                  this.$nextTick(() => {
                    this.$refs.chChat && this.$refs.chChat.scrollToBottom();
                  });
                }
                break;
            }
          },
        },
      );
    },
    updateTags(tag, id, deleted) {
      if (deleted) {
        this.tags = this.tags.filter((item) => item !== tag);
        return;
      }
      !this.tags.includes(tag) && this.tags.push(tag);
    },
    buildActions(actionJsons) {
      if (!actionJsons) return [];

      let actions = [];
      actionJsons.forEach((json) => {
        if (json.action) {
          const datetime = util.relativeDay(json.created_at) + ", " + util.time_12(json.created_at);
          const user = this.teamMembers.find((m) => m.id === json.user_id);
          let username = user ? user.user_name : "";
          let actionText = "",
            targetUsername;
          switch (json.action) {
            case "reply":
              actionText = "replied to";
              break;
            case "replyall":
              actionText = "replied all to";
              break;
            case "forward":
              actionText = "forwarded to";
              break;
            default:
              actionText = json.action;
              username = "";
          }
          if (json.email_participants && json.email_participants.length > 0)
            targetUsername = json.email_participants.map((p) => p.username).join(", ");

          actions.push({ datetime, username, actionText, targetUsername });
        }
      });

      return actions;
    },
    canDeteleTag(tag) {
      return tag != appConstant.trigonalTag;
    },
  },
  watch: {
    currentTab: "tabChange",
    showTab(val) {
      val && this.tabChange();
    },
  },
  components: {
    "ch-chat": chat,
    "email-tab-panel": EmailTabPanel,
    MailThreads,
    assignment,
    avatar,
  },
};
</script>
<style lang="scss" scoped>
.tabs-wrapper {
  width: 300px;
  min-width: 300px;
  border: solid 1px var(--border-color);
  border-left: none;
  color: var(--text-color);
  .details-contain {
    position: relative;
    height: 100%;
    overflow: auto;
    overflow: overlay;
    padding: 30px 16px 0 16px;
    .details-item {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 12px;
      font-size: 14px;
      color: var(--label-color);

      .mail-header {
        img {
          margin-right: 12px;
        }
      }
      .details-header {
        display: flex;
        align-items: center;
        font-weight: bold;
        i.iconfont,
        [class^="iconfont-"] {
          vertical-align: middle;
          margin-right: 8px;
        }
      }
      .noData {
        width: 100%;
        padding: 20px 0;
        text-align: center;
      }
      .details-content {
        width: 100%;
        padding: 16px 0;
        text-align: left;
        color: var(--text-color);
        .mail-boxes {
          background-color: var(--hover-color);
          color: var(--text-color);
          padding: 16px;
          border-radius: 8px;
          text-transform: capitalize;
          margin-bottom: 10px;
          .dot {
            display: inline-block;
            background-color: #9e42ff;
            width: 10px;
            min-width: 10px;
            height: 10px;
            border-radius: 100%;
            margin-right: 10px;
            vertical-align: middle;
          }
        }
        .mail-boxes:last-child {
          margin: 0;
        }
        .tagItem {
          display: inline-block;
          border: 1px solid var(--border-color);
          padding: 1px 8px;
          text-align: center;
          margin-right: 11px;
          margin-bottom: 5px;
          border-radius: 20px;
          color: var(--text-color);

          .ch-icon-cross_1 {
            color: #b2bcc1;
            font-size: 10px;
            cursor: pointer;
            margin-left: 2px;
            &:hover {
              color: #959fa4;
            }
          }
        }
        .actions {
          .action-item {
            list-style: none;
            margin-bottom: 8px;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.43;
            text-align: left;
            color: var(--text-color);
          }
          .action-datetime {
            color: var(--label-color);
          }
        }
        &.folders {
          li {
            display: flex;
            padding: 16px;
            text-align: left;
            border-radius: 8px;
            background-color: var(--hover-color);
            color: var(--text-color);
            &.folder-info {
              flex-direction: column;
              h1 {
                word-wrap: break-word;
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 8px;
                cursor: pointer;
              }
              p {
                word-wrap: break-word;
                font-size: 13px;
                text-align: left;
              }
              .filed-by {
                .rules {
                  text-decoration: underline;
                  color: var(--primary-color);
                  cursor: pointer;
                }
              }
              .filed-date {
                color: var(--label-color);
              }
            }
            img {
              margin-left: auto;
              width: 30px;
              height: 30px;
              border-radius: 100%;
            }
          }
          li:not(:first-child) {
            margin-top: 8px;
          }
        }
        &.mentions {
          .mention-item {
            display: inline-block;
            position: relative;
            & + .mention-item {
              margin-left: 4px;
            }
            .avatar {
              cursor: default;
              display: inline-block;
            }
            .ic-remove-mention {
              position: absolute;
              left: 22px;
              top: 0;
              background: var(--component-color);
              border-radius: 50%;
              border: 1px solid var(--border-color);
              z-index: 100000;
              color: var(--on-component-color);
              cursor: pointer;
              display: none;
              &:hover {
                font-weight: bold;
              }
            }
            &:hover {
              .ic-remove-mention {
                display: inline;
              }
            }
          }
        }
      }
    }
  }
  .positions-wrapper {
    .positions-contain {
      margin-bottom: 4px;

      .position-title {
        font-size: 14px;
        padding: 0 10px;
        width: 100%;
        line-height: 40px;
        background: var(--background-color);
      }
      .position-list {
        .list-item {
          display: flex;
          padding-right: 10px;
          border-bottom: 1px solid var(--border-color);
          .indexNumber {
            width: 35px;
            flex-shrink: 0;
            font-size: 14px;
            padding: 10px 0;
            text-align: center;
          }
          .positionInfo {
            width: calc(100% - 35px);
            margin: 5px 0;
            text-align: left;
            padding: 5px 0 5px 6px;
            border-left: 1px solid var(--border-color);
            .note {
              word-wrap: break-word;
              word-break: normal;
            }
          }
        }
      }
    }
    .addPosition {
      width: 260px;
      height: 40px;
      line-height: 40px;
      color: #ffffff;
      text-align: center;
      margin: 16px auto;
      font-size: 14px;
      background: var(--blue-main);
      cursor: pointer;
      span {
        padding-left: 6px;
      }
    }
  }
}
.vertical {
  background-color: var(--surface-color);
  height: 100%;
  box-shadow: 0px 0px 5px var(--border-color);
}
</style>

<style lang="scss">
.tabs-wrapper {
  .mentions {
    .ivu-tooltip-rel {
      display: flex;
      align-items: center;
    }
  }
}
</style>
