import { render, staticRenderFns } from "./addContact.vue?vue&type=template&id=5448781d&scoped=true"
import script from "./addContact.vue?vue&type=script&lang=js"
export * from "./addContact.vue?vue&type=script&lang=js"
import style0 from "./addContact.vue?vue&type=style&index=0&id=5448781d&prod&lang=scss&scoped=true&rel=stylesheet%2Fscss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5448781d",
  null
  
)

export default component.exports